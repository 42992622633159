<template>
  <div id="news">
    <v-toolbar flat>
      <v-spacer />
      <v-btn color="primary" :to="{ name: 'admin:news_new_form' }" elevation="0"><v-icon small left>mdi-plus-circle</v-icon>新規登録</v-btn>
    </v-toolbar>

    <v-container>
      <v-card>
        <v-card-text>
            <v-data-table class="clickable" :headers="headers" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 250, 500, 1000], showFirstLastPage: true }" :items="news" :options.sync="options" :server-items-length="totalPassengers" :loading="loading" @click:row="changeView">
              <template v-slot:item.subject="{ item }">
                {{ item.subject }}
              </template>
              <template v-slot:item.create_at="{ item }">
                {{ item.create_at | moment("YYYY/MM/DD") }}
              </template>
              <template v-slot:item.release_flag="{ item }">
                <v-chip small v-if="item.release_flag == 1" color="primary" class="d-block">公開</v-chip>
                <v-chip small v-else class="d-block">非公開</v-chip>
              </template>
            </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'news',
  methods: {
    changeView: function (value) {
      const id = value.id
      if (id) this.$router.push({ name: 'admin:news_show', params: { id: id } })
    },
    readDataFromAPI: function () {
      this.loading = true
      const queryParams = {
        sort: this.options.sortBy.length === 0 ? 'create_at' : this.options.sortBy[0],
        direction: this.options.sortDesc.length === 0 ? 'desc' : (this.options.sortDesc[0] ? 'desc' : 'asc'),
        per_page: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
        page: this.options.page
      }
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/news`, {
        params: queryParams
      })
        .then(res => {
          this.loading = false
          this.news = res.data.data
          this.totalPassengers = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  data: function () {
    return {
      news: [],
      loading: true,
      totalPassengers: 0,
      options: {
        itemsPerPage: 10,
        sortDesc: [
          true
        ]
      },
      headers: [
        {
          text: '',
          value: 'release_flag',
          width: 85
        },
        {
          text: 'タイトル',
          value: 'subject',
          cellClass: 'pl-0'
          // divider: true
        },
        {
          text: '作成日',
          value: 'create_at',
          align: 'center',
          width: 180
        }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.readDataFromAPI()
      },
      deep: true
    }
  }
}
</script>
